import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardTitle,
  CardText,
} from "reactstrap";

const IngresarViajes = (props) => {
  var hoy = new Date();
  var hoyFecha =
    hoy.getFullYear() + "-" + (hoy.getMonth() + 1) + "-" + hoy.getDate();
  var hoyHora =
    hoy.getHours() + ":" + hoy.getMinutes() + ":" + hoy.getSeconds();

  //Valores iniciales del estado
  const initialStatesValues = {
    fecha: hoyFecha,
    hora: hoyHora,
    date: "",
    detalle: "",
    placa:"",
    valorViaje: "",
    anticipo: 0,
    porcentajeConductor: "",
    peaje: "",
    parqueo: "",
    otrosGastos: "",
    acpm: "",
    saldo: 0,
  };

  //inicio estado
  const [values, setValues] = useState(initialStatesValues);

  //Estado del modal
  const [modal, setModal] = useState(false);

  //Capturar cambios al tipear
  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      porcentajeConductor: values.valorViaje * 0.12,
      anticipo:
        +values.otrosGastos +
        +values.parqueo +
        +values.peaje +
        +values.porcentajeConductor,
    });
    console.log(values);
  };

  const calcularSaldo = () => {
    values.saldo = +values.valorViaje - +values.anticipo - +values.acpm;
  };

  //Evento del submit
  const HandleSumbit = (e) => {
    e.preventDefault();
    calcularSaldo();
    props.addOrEditViajes(values);
    setValues({ ...initialStatesValues });
    toggle();
  };

  //Cambio del toggle del modal
  const toggle = () => setModal(!modal);

  return (
    <div>
      <Card body align="center">
        {/*Encabezado*/}
        <CardTitle tag="h2">Control de Transportes</CardTitle>
        <CardText>Administra los viajes diarios que se realizan.</CardText>
        <Button className="btn btn-block pb-2" color="info" onClick={toggle}>
          Agregar nuevo transporte
        </Button>
      </Card>
      <br />
      <Modal centered scrollable size="xl" isOpen={modal}>
        <ModalHeader>Ingresar viaje</ModalHeader>
        <ModalBody>
          <form onSubmit={HandleSumbit} className="card card-body">
            <div className="card card-body">
              {/* inputs del formulario */}
              <div className="form-group input-group pb-2">
                <div className="input-group-text bg-light">
                  <i className="material-icons">info_outline</i>
                </div>

                {/* Input placa */}
                <input
                required
                  type="text"
                  className="form-control"
                  placeholder="Vehículo"
                  name="placa"
                  onChange={handleInputChanges}
                  value={values.placa}
                />

                {/* Input detalle */}
                <input
                required
                  type="text"
                  className="form-control"
                  placeholder="Detalle del transporte"
                  name="detalle"
                  onChange={handleInputChanges}
                  value={values.detalle}
                />
              </div>

              <div className="form-group input-group pb-2">
                <div className="input-group-text bg-light">
                  <i className="material-icons">attach_money</i>
                </div>
                {/* Input valor del viaje */}
                <input
                required
                  type="number"
                  className="form-control"
                  placeholder="Valor del viaje"
                  name="valorViaje"
                  onChange={handleInputChanges}
                  value={values.valorViaje}
                />
                <div className="input-group-text bg-light">
                  <i className="material-icons">attach_money</i>
                </div>
                {/* Input peaje */}
                <input
                required
                  type="number"
                  className="form-control"
                  placeholder="Peaje"
                  name="peaje"
                  onChange={handleInputChanges}
                  value={values.peaje}
                />
              </div>
              <div className="form-group input-group pb-2">
                <div className="input-group-text bg-light">
                  <i className="material-icons">attach_money</i>
                </div>
                {/* Input parqueo */}
                <input
                required
                  type="number"
                  className="form-control"
                  placeholder="Parqueo"
                  name="parqueo"
                  onChange={handleInputChanges}
                  value={values.parqueo}
                />

                <div className="input-group-text bg-light">
                  <i className="material-icons">attach_money</i>
                </div>
                {/* Input otros gastos */}
                <input
                required
                  type="number"
                  className="form-control"
                  placeholder="Otros gastos"
                  name="otrosGastos"
                  onChange={handleInputChanges}
                  value={values.otrosGastos}
                />
              </div>

              <div className="form-group input-group pb-2">
                <div className="input-group-text bg-light">
                  <i className="material-icons">attach_money</i>
                </div>
                {/* Input ACPM */}
                <input
                required
                  type="number"
                  className="form-control"
                  placeholder="ACPM"
                  name="acpm"
                  onChange={handleInputChanges}
                  value={values.acpm}
                />
              </div>
            </div>
            <br />
            {/* Labels de los cálculos */}
            <div className="card card-body">
              <div className="form-group input-group pb-2">
                <div className="input-group-text bg-light">
                  <span>Anticipo:</span>
                </div>
                {/* Anticipo */}
                <label
                  type="text"
                  className="form-control"
                  placeholder="Anticipo"
                  name="anticipo"
                  onChange={handleInputChanges}
                  value={values.anticipo}
                >
                  {values.anticipo}
                </label>
                {/* porcentaje del conductor */}
                <div className="input-group-text bg-light">
                  <span>Porcentaje del conductor:</span>
                </div>
                <label
                  type="text"
                  className="form-control"
                  placeholder="Porcentaje del conductor"
                  name="porcentajeConductor"
                  onChange={handleInputChanges}
                  value={values.porcentajeConductor}
                >
                  {values.porcentajeConductor}
                </label>
              </div>
            </div>
            <br />
            <div className="card card-body">
              <button className="btn btn-primary btn-block">
                Guardar y enviar
              </button>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default IngresarViajes;
